import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css';

const LandingPage = () => {
  const [titleFadeOut, setTitleFadeOut] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [finalFadeOut, setFinalFadeOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Start title fade out after 2 seconds
    const titleTimer = setTimeout(() => {
      setTitleFadeOut(true);
    }, 2000);

    // Show disclaimer after title fades out (3 seconds total)
    const disclaimerTimer = setTimeout(() => {
      setShowDisclaimer(true);
    }, 3000);

    // Show button after disclaimer appears (4 seconds total)
    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 4000);

    return () => {
      clearTimeout(titleTimer);
      clearTimeout(disclaimerTimer);
      clearTimeout(buttonTimer);
    };
  }, []);

  const handleContinue = () => {
    setFinalFadeOut(true);
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  };

  return (
    <div className={`landing-container ${finalFadeOut ? 'fade-out' : ''}`}>
      <div className="landing-content">
        <h1 className={`landing-title ${titleFadeOut ? 'fade-out' : ''}`}>
          RadCoPilot
        </h1>
        
        {showDisclaimer && (
          <div className="disclaimer-section">
            <div className="disclaimer-text">
              <p><strong>Educational Purpose Only</strong></p>
              <p>RadCoPilot is designed as an educational tool to assist in learning and understanding radiological reporting.</p>
              
              <p><strong>Important Disclaimers:</strong></p>
              <ul>
                <li>This is NOT a diagnostic tool and should not be used for making clinical decisions.</li>
                <li>AI systems make mistakes and can provide incorrect or incomplete information.</li>
              </ul>

              <p><strong>Patient Privacy Warning:</strong></p>
              <ul>
                <li>Do NOT enter any patient identifiable information.</li>
                <li>This includes but is not limited to:
                  <ul className="nested-list">
                    <li>Patient names</li>
                    <li>Hospital numbers</li>
                    <li>Dates of birth</li>
                    <li>Any other identifying information</li>
                  </ul>
                </li>
                <li>Use only anonymized clinical information for educational purposes.</li>
              </ul>
            </div>

            {showButton && (
              <button 
                className="continue-button"
                onClick={handleContinue}
              >
                I Understand & Accept
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;