import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sidebar from './Sidebar';
import '../styles/RadCoPilot.css';
import { getAutocompletion, getAutodeletion } from '../api/openai';

const RadCoPilot = ({ onLogout }) => {
  const [text, setText] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const textareaRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const latestRequestIdRef = useRef(0);
  const [selectedText, setSelectedText] = useState('');
  const [deleteSuggestion, setDeleteSuggestion] = useState('');
  const [autoCompleteEnabled, setAutoCompleteEnabled] = useState(true);
  const [autoDeleteEnabled, setAutoDeleteEnabled] = useState(true);
  const [ragEnabled, setRagEnabled] = useState(false);

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };
  
  const debouncedGetSuggestions = useCallback((newText) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    latestRequestIdRef.current++;
    const currentRequestId = latestRequestIdRef.current;

    debounceTimeoutRef.current = setTimeout(async () => {
      if (newText.trim() !== '') {
        try {
          const currentPosition = textareaRef.current.selectionStart;
          const beforeCursor = newText.slice(0, currentPosition);
          const afterCursor = newText.slice(currentPosition);
          const textWithCursor = `${beforeCursor}|${afterCursor}`;
          
          if (autoCompleteEnabled) {
            const autocompletionResponse = await getAutocompletion(textWithCursor, ragEnabled);
            
            if (currentRequestId === latestRequestIdRef.current) {
              if (autocompletionResponse) {
                setSuggestion(autocompletionResponse);
                setDeleteSuggestion('');
              } else if (autoDeleteEnabled) {
                const autodeletionResponse = await getAutodeletion(newText, ragEnabled);
                setDeleteSuggestion(autodeletionResponse || '');
                setSuggestion(autodeletionResponse || '');
              }
            }
          } else if (autoDeleteEnabled) {
            const autodeletionResponse = await getAutodeletion(newText, ragEnabled);
            setDeleteSuggestion(autodeletionResponse || '');
            setSuggestion(autodeletionResponse || '');
          }
        } catch (error) {
          console.error('Error getting suggestions:', error);
        }
      }
    }, 500);
  }, [autoCompleteEnabled, autoDeleteEnabled, ragEnabled]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    
    const newPosition = e.target.selectionStart;
    setCursorPosition(newPosition);
    
    textareaRef.current.selectionStart = newPosition;
    textareaRef.current.selectionEnd = newPosition;
    
    setSuggestion('');
    debouncedGetSuggestions(newText);
  };

  const handleKeyDown = (e) => {
    // Handle deletion suggestions
    if (deleteSuggestion && suggestion === deleteSuggestion) {
        if (e.key === 'Tab') {
            e.preventDefault();
            const textToDelete = deleteSuggestion;
            
            const deletePosition = text.indexOf(textToDelete);
            if (deletePosition !== -1) {
                const currentCursorPos = textareaRef.current.selectionStart;
                
                const newCursorPos = currentCursorPos > deletePosition + textToDelete.length
                    ? currentCursorPos - textToDelete.length
                    : currentCursorPos > deletePosition
                        ? deletePosition
                        : currentCursorPos;

                const newText = text.slice(0, deletePosition) + text.slice(deletePosition + textToDelete.length);
                setText(newText);
                setCursorPosition(newCursorPos);
                
                setSuggestion('');
                setDeleteSuggestion('');

                // Immediately trigger new suggestions
                debouncedGetSuggestions(newText);

                setTimeout(() => {
                    textareaRef.current.selectionStart = newCursorPos;
                    textareaRef.current.selectionEnd = newCursorPos;
                }, 0);
            }
        } else if (e.key === 'Backspace') {
            e.preventDefault();
            setDeleteSuggestion('');
            setSuggestion('');  
        } else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(e.key)) {
            setDeleteSuggestion('');
            setSuggestion('');
        }
        return;
    }

    // Handle autocompletion suggestions
    if (suggestion && !deleteSuggestion) {
        if (e.shiftKey && e.key === 'Tab') {
            // Handle Shift+Tab for single word
            e.preventDefault();
            const words = suggestion.split(' ');
            const firstWord = words[0];
            
            const beforeCursor = text.slice(0, cursorPosition);
            const afterCursor = text.slice(cursorPosition);
            
            const newText = beforeCursor + firstWord + ' ' + afterCursor;
            setText(newText);
            
            const newPosition = cursorPosition + firstWord.length + 1;
            setCursorPosition(newPosition);
            
            const remainingSuggestion = words.slice(1).join(' ');
            setSuggestion(remainingSuggestion);

            if (!remainingSuggestion) {
                setSuggestion('');
                setDeleteSuggestion('');
                // Immediately trigger new suggestions
                debouncedGetSuggestions(newText);
            }
            
            setTimeout(() => {
                textareaRef.current.selectionStart = newPosition;
                textareaRef.current.selectionEnd = newPosition;
            }, 0);
        } else if (e.key === 'Tab') {
            // Handle Tab for full suggestion
            e.preventDefault();
            const beforeCursor = text.slice(0, cursorPosition);
            const afterCursor = text.slice(cursorPosition);
            
            const newText = beforeCursor + suggestion + ' ' + afterCursor;
            setText(newText);
            
            const newPosition = cursorPosition + suggestion.length + 1;
            setCursorPosition(newPosition);
            
            setSuggestion('');
            setDeleteSuggestion('');
            
            // Immediately trigger new suggestions
            debouncedGetSuggestions(newText);

            setTimeout(() => {
                textareaRef.current.selectionStart = newPosition;
                textareaRef.current.selectionEnd = newPosition;
            }, 0);
        }
    }

    // Handle other key events
    if (e.key === 'Backspace' && suggestion) {
        e.preventDefault();
        setSuggestion('');
        setDeleteSuggestion('');
    } else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(e.key)) {
        setSuggestion('');
        setDeleteSuggestion('');
    }
};

  // Modify the handleClick function
  const handleClick = (e) => {
    const newPosition = e.target.selectionStart;
    
    // Clear suggestion if cursor moves
    if (newPosition !== cursorPosition) {
      setSuggestion('');
      setDeleteSuggestion('');
    }
    setCursorPosition(newPosition);
  };

  const handleSelect = (e) => {
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    
    // Clear suggestion if text is selected
    if (selectionStart !== selectionEnd) {
      setSuggestion('');
      setDeleteSuggestion('');
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    
    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      setSelectedText('');
    };
  }, []);

  // Chat interface text selection
  const handleTextSelection = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value.substring(start, end).trim();
      
      if (text && start !== end) {
        setSelectedText(text);
      } else {
        setSelectedText('');
      }
    }
  };

  const handleSelectReport = (reportContent) => {
    setText(reportContent);
  };


  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const renderTextWithSuggestion = () => {
    if (!suggestion) return text;
    
    if (deleteSuggestion && suggestion === deleteSuggestion) {
      const parts = text.split(deleteSuggestion);
      return (
        <>
          {parts[0]}
          <span className="suggestion-text deletion">{deleteSuggestion}</span>
          {parts[1]}
        </>
      );
    }
    
    const beforeCursor = text.slice(0, cursorPosition);
    const afterCursor = text.slice(cursorPosition);
    
    return (
      <>
        {beforeCursor}
        <span className="suggestion-text">{suggestion}</span>
        {afterCursor}
      </>
    );
  };

  return (
    <div className={`radcopilot-container ${sidebarOpen ? 'shifted' : ''}`}>
      <Sidebar 
        isOpen={sidebarOpen}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        onLogout={onLogout}
        toggleHelp={toggleHelp}
        onSelectReport={handleSelectReport}
        text={text}
        setText={setText}
        setSuggestion={setSuggestion}
        setShowPopup={setShowPopup}
        selectedText={selectedText}
        autoCompleteEnabled={autoCompleteEnabled}
        setAutoCompleteEnabled={setAutoCompleteEnabled}
        autoDeleteEnabled={autoDeleteEnabled}
        setAutoDeleteEnabled={setAutoDeleteEnabled}
        ragEnabled={ragEnabled}
        setRagEnabled={setRagEnabled}
      />
      
      {showHelp && (
        <div className="help-modal">
          <h3>Keyboard Shortcuts</h3>
          <ul>
            <li><strong>Shift + Tab:</strong> Insert the first word of the suggestion</li>
            <li><strong>Tab:</strong> Insert the entire suggestion</li>
            <li><strong>Backspace:</strong> Clear the suggestion</li>
          </ul>
          <button onClick={toggleHelp}>Close</button>
        </div>
      )}
      
      <div className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
        <div className="radcopilot-main">
          <div className="textarea-wrapper">
            <div className="text-content">
              {renderTextWithSuggestion()}
            </div>
            <textarea
              ref={textareaRef}
              value={text}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onClick={handleClick}
              onSelect={handleSelect}
              onMouseUp={handleTextSelection}
              className="rad-textarea"
              placeholder="Write your report here..."
            />
          </div>
        </div>
        
        {showPopup && (
          <div className="copy-popup">
            Report saved successfully and copied to clipboard!
          </div>
        )}
      </div>
    </div>
  );
};

export default RadCoPilot;
