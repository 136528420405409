import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_URL = isDevelopment 
  ? 'http://127.0.0.1:8000/auth/'
  : 'https://radcopilot-development-099783b30a41.herokuapp.com/auth/';

export const register = (username, email, password, key) => {
  return axios.post(API_URL + 'register/', {
    username,
    email,
    password,
    key
  });
};

export const login = (username, password) => {
  return axios.post(API_URL + 'token/', {
    username,
    password
  });
};

export const refreshToken = (refresh) => {
  return axios.post(API_URL + 'token/refresh/', {
    refresh
  });
};
