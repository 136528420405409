import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';
import '../styles/Sidebar.css';
import ReactMarkdown from 'react-markdown';
import { getChatResponse } from '../api/openai';

const Sidebar = ({ 
  isOpen, 
  toggleSidebar, 
  onLogout, 
  onSelectReport,
  text,
  setText,
  setSuggestion,
  setShowPopup,
  selectedText,
  autoCompleteEnabled,
  setAutoCompleteEnabled,
  autoDeleteEnabled,
  setAutoDeleteEnabled,
  ragEnabled,
  setRagEnabled
}) => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeView, setActiveView] = useState('main');
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatLoading, setChatLoading] = useState(false);
  const [isButtonsCollapsed, setIsButtonsCollapsed] = useState(false);
  
  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get('/api/report-history/');
        const reportData = Array.isArray(response.data) ? response.data : [];
        setReports(reportData);
        
        // Debug logging for reports
        console.group('Report History Debug');
        console.log('Total reports fetched:', reportData.length);
        console.log('Reports being used as context:', 
          reportData.filter(report => report.is_context)
            .map(report => ({
              id: report.id,
              preview: report.content.substring(0, 50) + '...',
              created_at: report.created_at,
              embedding_status: report.embedding_status
            }))
        );
        console.groupEnd();

        setError(null);
      } catch (error) {
        console.error('Error fetching report history:', error);
        setError('Failed to fetch reports. Please try again.');
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleSaveAndCopy = async () => {
    if (!text.trim()) {
      return; // Don't save empty reports
    }

    try {
      await axiosInstance.post('/api/report-history/', {
        content: text,
        create_embedding: ragEnabled
      });

      // Copy to clipboard
      await navigator.clipboard.writeText(text);
      
      // Show success popup
      setShowPopup(true);
      
      // Clear the text area
      setText('');
      setSuggestion('');
      
      // Refresh the reports list
      const response = await axiosInstance.get('/api/report-history/');
      setReports(Array.isArray(response.data) ? response.data : []);
      
      // Hide popup after 2 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);

    } catch (error) {
      console.error('Error saving/copying report:', error);
      alert('Failed to save or copy the report. Please try again.');
    }
  };

  const handleChatClick = async () => {
    // Use the entire text if no text is selected
    const textToProcess = selectedText || text;
    
    const initialMessage = { role: 'user', content: textToProcess };
    setChatMessages([initialMessage]);
    setActiveView('chat');
    setChatLoading(true);
    console.log('Initial message:', initialMessage);
    console.log('Sending messages:', [initialMessage]);
    
    try {
      const response = await getChatResponse([initialMessage]);
      console.log('Chat response:', response); 
      const assistantMessage = { role: 'assistant', content: response };
      setChatMessages([initialMessage, assistantMessage]);
    } catch (error) {
      console.error('Error getting chat response:', error);
    } finally {
      setChatLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const userMessage = { role: 'user', content: newMessage };
    const updatedMessages = [...chatMessages, userMessage];
    setChatMessages(updatedMessages);
    setNewMessage('');
    setChatLoading(true);

    try {
      // Log the messages being sent for debugging
      console.log('Sending messages:', updatedMessages);
      const response = await getChatResponse(updatedMessages);
      console.log('Received response:', response);
      const assistantMessage = { role: 'assistant', content: response };
      setChatMessages([...updatedMessages, assistantMessage]);
    } catch (error) {
      console.error('Error getting chat response:', error);
      // Optionally, handle the error by showing a message to the user
    } finally {
      setChatLoading(false);
    }
  };

  const renderChatView = () => (
    <div className="sidebar-section">
      <div className="sidebar-section-content chat-view">
        <h3>RadChat</h3>
        <div className="chat-messages">
          {chatMessages.map((message, index) => (
            <div key={index} className={`chat-message ${message.role}`}>
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </div>
          ))}
          {chatLoading && <p>Loading...</p>}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            placeholder="Type your message..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );

  const renderSettings = () => (
    <div className="sidebar-section">
      <div className="sidebar-section-content">
        <h3>Settings</h3>
        <div className="settings-toggles">
          <div className="setting-item">
            <span className="setting-label">AI Suggestions</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={autoCompleteEnabled}
                onChange={(e) => setAutoCompleteEnabled(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="setting-item">
            <span className="setting-label">AI Deletions</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={autoDeleteEnabled}
                onChange={(e) => setAutoDeleteEnabled(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="setting-item">
            <span className="setting-label">Use Report History Context</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={ragEnabled}
                onChange={(e) => setRagEnabled(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );

  const renderReportHistory = () => (
    <div className="sidebar-section">
      <div className="sidebar-section-content">
        <h3>Report History</h3>
        {isLoading ? (
          <p>Loading reports...</p>
        ) : error ? (
          <p>{error}</p>
        ) : reports.length === 0 ? (
          <p>No reports found.</p>
        ) : (
          <ul className="report-list">
            {reports.map((report) => (
              <li 
                key={report.id} 
                onClick={() => onSelectReport(report.content)}
                className={`report-item ${report.is_context ? 'used-as-context' : ''}`}
              >
                <div className="report-header">
                  <span className="report-date">
                    {report.created_at ? new Date(report.created_at).toLocaleString() : 'Date not available'}
                  </span>
                  {report.embedding_status && (
                    <span className={`embedding-status ${report.embedding_status.toLowerCase()}`}>
                      {report.embedding_status}
                    </span>
                  )}
                </div>
                <p className="report-preview">
                  {report.content.substring(0, 50)}
                  {report.content.length > 50 ? '...' : ''}
                </p>
                {report.is_context && (
                  <span className="context-badge">Used as Context</span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeView) {
      case 'help':
        return (
          <div className="sidebar-section">
            <div className="sidebar-section-content">
              <h3>Keyboard Shortcuts</h3>
              <ul>
                <li><strong>Shift + Tab:</strong> Insert first word of suggestion</li>
                <li><strong>Tab:</strong> Insert entire suggestion</li>
                <li><strong>Backspace:</strong> Clear suggestion</li>
              </ul>
            </div>
          </div>
        );

      case 'chat':
        return renderChatView();

      case 'history':
        return renderReportHistory();

      case 'settings':
        return renderSettings();

      default:
        return null;
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="sidebar-toggle" onClick={toggleSidebar} aria-label="Toggle Sidebar">
        <span></span>
        <span></span>
        <span></span>
      </button>
      
      <div className="sidebar-content">
        <div className="sidebar-title">
          <span className="rad">Rad</span><span className="copilot">CoPilot</span>
        </div>

        <div className="sidebar-buttons-container">
          <button 
            className="collapse-toggle"
            onClick={() => setIsButtonsCollapsed(!isButtonsCollapsed)}
          >
            {isButtonsCollapsed ? '▼' : '▲'}
          </button>
          <div className={`sidebar-buttons ${isButtonsCollapsed ? 'collapsed' : ''}`}>
            <button 
              className={`sidebar-button ${activeView === 'help' ? 'active' : ''}`}
              onClick={() => setActiveView(activeView === 'help' ? 'main' : 'help')}
            >
              Help
            </button>
            
            <button 
              className="sidebar-button"
              onClick={handleSaveAndCopy}
            >
              Save and Copy Report
            </button>
            
            <button 
              className={`sidebar-button ${activeView === 'chat' ? 'active' : ''}`}
              onClick={handleChatClick}
            >
              RadChat
            </button>

            <button 
              className={`sidebar-button ${activeView === 'history' ? 'active' : ''}`}
              onClick={() => setActiveView(activeView === 'history' ? 'main' : 'history')}
            >
              Report History
            </button>
            
            <button 
              className={`sidebar-button ${activeView === 'settings' ? 'active' : ''}`}
              onClick={() => setActiveView(activeView === 'settings' ? 'main' : 'settings')}
            >
              Settings
            </button>
            
            <button 
              className="sidebar-button"
              onClick={onLogout}
            >
              Logout
            </button>
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default Sidebar;